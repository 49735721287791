<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Các câu hỏi trong dang mục {{ title_category }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogFaqQuestion"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm câu hỏi</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại câu hỏi</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Is Tick</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(question, i) in faq_questions">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="question.title"
                        ></p>
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4"
                          v-bind:class="
                            question.is_tick === 1
                              ? 'label-light-success'
                              : 'label-light-warning'
                          "
                        >
                          {{ question.is_tick === 1 ? "Có" : "Không" }}
                        </span>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="editQuestion(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteQuestion(question.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="diaLogFaqQuestion"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form === 'add'" class="headline"
                >Thêm câu hỏi</span
              >
              <span v-if="type_form === 'update'" class="headline"
                >Sửa câu hỏi</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="diaLogFaqQuestion = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <label class="text-h6">Nhập tên tiêu đề câu hỏi:</label>
                  <v-text-field
                    filled
                    v-model="title_input"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="d-flex pt-0">
                  <label class="text-h6 p-0">Thêm tick sao cho câu hỏi:</label>
                  <v-radio-group
                    v-model="is_tick_input"
                    row
                    class="mt-0 p-0 ml-1"
                    dense
                  >
                    <v-radio label="No" value="no"></v-radio>
                    <v-radio label="Yes" value="yes"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label class="text-h6">Nhập nội dung câu hỏi</label>
                  <Editor v-model="content_input"></Editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEdit(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEdit(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="diaLogFaqQuestion = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại danh mục</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(question, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ question.title }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortFaqQuestion"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "@/components/ckeditor5/Editor";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  name: "FaqQuestion",
  components: {
    Editor,
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      title_category: "",
      faq_questions: [],
      type_form: null,
      title_input: "",
      content_input: "",
      is_tick_input: 0,
      offset_input: null,
      faq_question_id: null,
      diaLogFaqQuestion: false,
      data_draggable: [],
      dialogSort: false,
    };
  },
  computed: {
    faq_category_id: function () {
      return this.$route.params.faq_category_id;
    },
  },
  async created() {
    this.is_call_api = true;
    await this.getFaqQuestion();
    this.is_call_api = false;
  },
  methods: {
    async getFaqQuestion() {
      let vm = this;
      try {
        let res = await ApiService.get(
          "prep-app/faq-category/" + this.faq_category_id + "/question"
        );
        if (res.status === 200) {
          vm.title_category = res.data.category.title;
          vm.faq_questions = res.data.questions;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDiaLogFaqQuestion() {
      this.type_form = "add";
      this.title_input = "";
      this.content_input = "";
      this.is_tick_input = "no";
      this.offset_input = this.faq_questions.length;
      this.diaLogFaqQuestion = true;
    },
    editQuestion(index) {
      let data = this.faq_questions[index];
      this.title_input = data.title;
      this.content_input = data.content === null ? "" : data.content;
      this.is_tick_input = data.is_tick === 1 ? "yes" : "no";
      this.offset_input = data.offset;
      this.faq_question_id = data.id;
      this.type_form = "update";
      this.diaLogFaqQuestion = true;
    },
    btnAddOrEdit(type) {
      let vm = this;
      let data = {
        title: this.title_input,
        content: this.content_input,
        is_tick: this.is_tick_input === "yes" ? 1 : 0,
        offset: this.offset_input,
      };
      vm.is_call_api = true;
      if (type === 1) {
        ApiService.post(
          "prep-app/faq-category/" + this.faq_category_id + "/question",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.getFaqQuestion();
              vm.diaLogFaqQuestion = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put(
          "prep-app/faq-category/" +
            this.faq_category_id +
            "/question/" +
            this.faq_question_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.getFaqQuestion();
              vm.diaLogFaqQuestion = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    btnDeleteQuestion(faq_question_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete(
            "prep-app/faq-category/" +
              this.faq_category_id +
              "/question/" +
              faq_question_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getFaqQuestion();
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              vm.is_call_api = false;
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.faq_questions));
      this.dialogSort = true;
    },
    sortFaqQuestion() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              faq_question_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/faq-category/" + this.faq_category_id + "/question/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getFaqQuestion();
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
